<template>
  <div>
  </div>
</template>

<script>
const marked = require('marked');

export default {
  name: 'Blog',
  data: () => ({
    markdown: '# Marked in Node.js\n\nRendered by **marked**.',
    html: '',
  }),
  created() {
    this.html = marked(this.markdown);
  },
  watch: {
    markdown() {
      this.html = marked(this.markdown);
    },
  },
};
</script>

<style scoped>
</style>
